import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { CSSTransition } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import AppIcon from '../components/Images/AppIcon';
import Preview from '../components/Images/Preview';
import AppStore from '../images/appstore.svg';
import PlayStore from '../images/playstore.svg';
import EmailIcon from '../images/email.svg';
import { appInfo } from '../data/data';

// import WebInfoContext from '../context/context';

const Index = () => {
  // const { hero } = useContext(WebInfoContext);
  const { title, subtitle, iosLink, aosLink, appIcon, appPreview, mailTo } = appInfo;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
    setShowPreview(true)
  }, []);

  const aosOnClick = () => {
    console.log("Work in progress...")
  };

  return (
    <Layout>
      <section class="section">
          <div class="container" style={{ maxWidth: '680px', paddingTop: '6rem' }}>
            <div class="columns">
              <div class="column is-flex is-flex-direction-column	is-justify-content-center">
                <div class="block is-flex-mobile is-justify-content-center">
                  <div class="image is-96x96">
                    <AppIcon />{' '}
                  </div>
                </div>
                <div class="block">
                  <h1 class="title is-2 has-text-white has-text-centered has-text-left-tablet	">
                    {title || 'App Name'}{' '}
                  </h1>
                </div>
                <div class="block">
                  <h2 class="subtitle is-5  has-text-white has-text-centered has-text-left-tablet">
                    {subtitle || 'Your awesome app description.'}
                  </h2>
                </div>

                <div class="block has-text-centered has-text-left-tablet">
                  <Link href={mailTo}>
                    <EmailIcon class="has-text-white" />
                  </Link>
                  {/* <Link
                    class="ml-2 is-size-7	has-text-white"
                    to="https://spectrum.chat/loopplayerab-ios"
                    style={{ textDecoration: 'underline' }}
                  >
                    App Support 
                  </Link> */}
                  <Link
                    class="ml-2 is-size-7	has-text-white"
                    to="/privacy-policy"
                    style={{ textDecoration: 'underline' }}
                  >
                    Privacy Policy
                  </Link>
                  <Link
                    class="ml-2 is-size-7	has-text-white"
                    to="/faq"
                    style={{ textDecoration: 'underline' }}
                  >
                    FAQ
                  </Link>
                </div>

                <div class="block has-text-centered has-text-left-tablet">
                  <Link href={iosLink} style={{ display: 'inline-block' }}>
                    <AppStore />{' '}
                  </Link>
                  <Link
                    class="ml-2"
                    href={'#'}
                    alt="Work in progress.."
                    onClick={aosOnClick}
                    style={{
                      display: 'inline-block',
                      filter: 'grayscale(105%)',
                      cursor: 'not-allowed',
                    }}
                  >
                    <PlayStore style={{ width: '135px', height: '40px' }} />
                  </Link>
                </div>
              </div>
              <div class="column is-hidden-mobile is-flex is-justify-content-center">
                <CSSTransition
                  in={showPreview}
                  unmountOnExit
                  timeout={3000}
                  classNames="preview"
                  onEnter={() => console.log('onEnter')}
                  onExited={() => console.log('onExited')}
                >
                  <div class="image" style={{ display: 'inline-block', width: '250px' }}>
                    <Preview />
                  </div>
                </CSSTransition>
              </div>
            </div>
        </div>
      </section>
    </Layout>
  );
};

export default Index;
