// import { nanoid } from 'nanoid';

const headData = {
  title: 'Audio Looper',
  lang: 'en',
  siteUrl: 'https://loop-player.adamliu.app/',
  description:
  'Audio Looper is is an audio repeat app designed for studying language, practice musical instruments. It supports mp3, mp4 and m4a files.',
};

const appInfo = {
  title: 'Audio Looper',
  subtitle:
    'Audio Looper is is an audio repeat app designed for studying language, practice musical instruments. It supports mp3, mp4 and m4a files.',
  iosLink: 'https://apps.apple.com/us/app/loop-player-ab/id1541657619',
  aosLink: '',
  appIcon: 'loop_player_icon.png',
  appPreview: 'preview_1.png',
  mailTo: 'mailto:adamliu2403+lp@gmail.com?subject=Audio Looper Query',
  email: 'adamliu2403@gmail.com',
};

module.exports = {
  headData,
  appInfo,
};
